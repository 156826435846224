<template>
  <div class="container mx-auto flex flex-col" :class="marque">
    <div class="w-10/12 md:w-8/12 flex mx-auto flex-wrap">
      <font class="w-full text-left text-3xl font-bold my-5" v-html="titre"></font>
      <div class="w-full text-left mb-5">
        <span 
        v-for="(n, index) in totalItems" 
        :key="index" 
        :class="{current: n-1 == currentItem}" 
        @click="currentItem = n-1" 
        class="dot text-center">
          <span>{{n}}</span>
        </span>
      </div>
    </div>
    <div v-for="(item, index) in content" :key="index" v-show="index == currentItem" class="_itemCarousel w-full md:w-8/12 flex justify-center flex-wrap relative flex-row-reverse mx-auto" :class="[item.text != undefined ? 'flex-row-reverse' : 'flex-col items-center']">
      <span class="_carouselArrow prev" @click="prev()">
        <div><span></span><span></span></div>
      </span>
      <span class="_carouselArrow next" @click="next()">
        <div><span></span><span></span></div>
      </span>
      <template v-if="item.titre != null">
        <div class="w-10/12 md:px-2 _image" :class="[item.text != undefined ? 'md:w-8/12' : 'md:w-full']">
          <img :src="item.img" alt="" class="w-full mb-4">
        </div>
        <div class="w-10/12 md:w-4/12 text-center" :class="[item.text != undefined ? 'md:w-4/12 md:text-left' : 'md:w-full']">
          <p v-html="item.titre" class="text-lg font-bold mb-4"></p>
          <p v-html="item.text" class="h_3"></p>
          <Cta v-if="item.cta != undefined" cls="btn2" class="mt-4 inline-block" :marque="marque" :destination="item.link != undefined ? item.link : undefined"><font class="uppercase">Je veux en savoir plus</font></Cta>
        </div>
      </template>
      <template v-else>
        <div class="w-10/12 md:w-full _image">
          <span class="_carouselArrow prev" @click="prev()">
            <div><span></span><span></span></div>
          </span>
          <span class="_carouselArrow next" @click="next()">
            <div><span></span><span></span></div>
          </span>
          <img :src="item.img" alt="" class="w-full">
        </div>
      </template>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    marque: String,
    content: Array,
    titre: String,
  },
  name: 'Carousel',
  data(){
    return{
      couleur1: '',
      couleur2: '',
      totalItems: null,
      currentItem: 0,
    }
  },
  methods: {
    carouselTotal() {
      let total = this.content.length
      this.totalItems = total
    },
    next() {
      let current = this.currentItem
      let total = this.totalItems
      current == total - 1 ? this.currentItem = 0 : this.currentItem = current + 1
    },
    prev() {
      let current = this.currentItem
      let total = this.totalItems
      current == 0 ? this.currentItem = total - 1 : this.currentItem = current - 1
    }
  },
  mounted(){
    this.carouselTotal()
  }
}
</script>

<style lang="scss" scoped>
.dot{
  @apply w-7 h-7 inline-block cursor-pointer relative mx-1;
  span{
    @apply w-full h-1 bottom-0 left-0 absolute text-transparent transition-all duration-300 ease-out;
    background-color: var(--carousel-color1);
  }
  &:hover span{
    @apply h-7 text-white;
  }
  &.current span{
    @apply h-7 text-white;
    background-color: var(--carousel-color2);
  }
}

$btnsize: 40px;
._carouselArrow{
  z-index: 9;
  bottom: calc(0px - $btnsize*1.25);
  width: $btnsize;
  height: $btnsize;
  border-color: var(--carousel-color1);
  border-radius: 50%;
  @apply bg-white border transition-all duration-300 ease-out absolute;
  &.prev{
    left: calc(50% - $btnsize*1.25);
    div{
      transform: rotate(90deg);
      top: 8px;
      left: 14px;
    }
  }
  &.next{
    right: calc(50% - $btnsize*1.25);
    div{
      transform: rotate(-90deg);
      top: 8px;
      right: 14px;
    }
  }
  div{
    @apply absolute inline-block;
  }
  span{
    @apply inline-block;
    background-color: var(--carousel-color1);
    width: 10px;
    height: 2px;
    &:first-child{
      transform: rotate(45deg);
    }
    &:last-child{
      transform: rotate(-45deg);
      margin-left: -4px;
    }
  }
}
@media (min-width: 768px){
  ._carouselArrow{
    bottom: calc(50% - $btnsize*0.5);
    &.prev{
      left: calc(0px - $btnsize*1.5);
    }
    &.next{
      right: calc(0px - $btnsize*1.5);
    }
  }
}
</style>