<template>
  <div class="leasys">
    <span class="inline-block px-4 py-2 uppercase mb-10 font-smooch text-4xl"><slot></slot></span>
  </div>
</template>

<script>
export default {
  name: 'Tampon'
}
</script>

<style lang="scss" scoped>
div>span{
  transform: rotate(-10deg);
  color: var(--secondary);
  border-style: solid;
  border-width: 2px;
  border-image: linear-gradient(90deg, var(--secondary) 0%, var(--secondary) 45%, rgba(12,41,84,0) 45%, rgba(12,41,84,0) 55%, var(--secondary) 55%, var(--secondary) 100%) 1;
}

</style>