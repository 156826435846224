<template>
  <div class="p-4 mt-8 w-full">
    <div v-if="conso != undefined" class="flex justify-center items-center flex-wrap">
      <img :src="$store.state.form.carImg" class="max-w-sm inline-block my-4">
      <img :src="'https://cdn2.jlrfrance.fr/uploads/consos/co2-'+conso+'.png'" class="w-52 inline-block m-4">
    </div>
    <div class="shadow shadow-2xl bg-gray-100 md:inline-block p-8 md:px-20">
      Au quotidien, prenez les transports en commun.<br>
      Pour les trajets courts, privilégiez la marche ou le vélo.<br>
      Pensez à covoiturer.<br><br>
      <font class="font-extrabold">#SeDéplacerMoinsPolluer</font>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Lom',
  props: {
    conso: String
  }

}
</script>

<style>

</style>