<template>
  <div class="w-full md:w-1/2 lg:w-1/3 p-2">
    <img :src="content.img" alt="" class="w-20 mx-auto mb-2">
    <p class="text-xl" v-html="content.text"></p>
  </div>
</template>

<script>
export default {
  name: 'Advantage',
  props: {
    content: Object
  }
}
</script>

<style>

</style>