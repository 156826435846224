<template>
  <span class="w-full inline-block my-4"></span>
</template>

<script>
export default {
  name: 'Hr'
}
</script>

<style lang="scss" scoped>
  span{
    height: 1px;
    background: linear-gradient(90deg, #ccffff 0%, rgba(204, 255, 255, 0) 50%);
  }
</style>